
import Vue from "vue";
import RadioButtonGroup from "@/components/FormBuilder/Components/RadioButtonGroup.vue";

export default Vue.extend({
  name: "agency-code-prefix-selector",
  components: {
    RadioButtonGroup
  },
  props: {
    companyIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      value: ""
    };
  },
  methods: {
    inputHandler(event: any) {
      this.value = event;
      this.$emit("prefixSelected", event);
      this.value = "";
    },
    closed() {
      this.$emit("closed");
    }
  },
  computed: {
    options() {
      let opts = [
        { label: "AGA", value: "AGA" },
        { label: "CLL", value: "CLL" },
        { label: "NONE", value: "NONE" }
      ];
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;

      let sites = companies
        .filter(
          (company: any) =>
            company.site && this.companyIds.includes(company._id)
        )
        .map((company: any) => company.site);
      sites = [...new Set(sites)];

      if (!sites.includes("ATLAS")) {
        opts = opts.filter((option: any) => option.value !== "AGA");
      }
      if (!sites.includes("COLONIAL")) {
        opts = opts.filter((option: any) => option.value !== "CLL");
      }
      return opts;
    }
  }
});
